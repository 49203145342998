@import url('https://fonts.googleapis.com/css?family=Oswald:regular,bold,italic,Light&subset=latin,latin-ext');

.App {
	text-align: start;
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
}

.App-header {
	background-color: #172155;
	font-family: 'Oswald', Impact, 'Arial Narrow', Light;
	height: 65px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	z-index: 4;
}

.title {
	font-size: calc(22px + 1vmin);
	color: #f7f7f7;
	margin-right: 10px;
}

.dropdown {
	font-family: 'Arial Narrow', Haettenschweiler, sans-serif;
	font-size: calc(20px + 1vmin);
	height: calc(35px + 1vmin);
	padding: 5px;
	border: none;
	background-color: #000240;
	color: #f7f7f7;
}

.header-content {
	display: flex;
	align-items: center;
	padding-right: 42px;
}

.contact-icon {
	cursor: pointer;
	position: fixed;
	right: 10px;
	margin-right: 3px;
	margin-left: 5px;
}

.filter-icon {
	cursor: pointer;
	position: fixed;
	right: 36px;
	margin-right: 3px;
	margin-left: 5px;
}

.slider-container.disabled {
	opacity: 0.5;
}

.main-content {
	flex-grow: 1;
	position: relative;
}

.GoogleMapContainer {
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.location-button {
	top: 10px;
	left: 216px;
}
.loading-screen {
	font-size: 17px;
	font-weight: bolder;
	font-family: 'Montserrat';
	padding-top: 50px;
	padding-bottom: 30px;
	margin-top: 25px;
	margin-inline: 48px;
	min-width: 150px;
	border-radius: 15px;
	width: stretch;
	text-align: center;
	align-items: center;
	background-color: rgb(80, 83, 145);
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
.close-button {
	float: right;
	margin-top: 10px;
	background-color: #fff;
	border: 1px solid #000;
	border-radius: 5px;
	padding: 5px 10px;
	cursor: pointer;
}

.close-button:hover {
	background-color: #000;
	color: #fff;
}
