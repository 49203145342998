.slider-container {
    font-family: 'Arial Narrow', sans-serif;
    font-weight: lighter;
    margin-left: 5px;
    margin-top: 36px;
    margin-right: 24px;
    align-items: center;
    width: stretch;
    padding: 1px;
}

.slider-container label {
    display: fill;
}

.slider-container input[type="range"],
.slider-container input[type="number"],
.input-container {
    text-align: center;
    align-items: center;
    background: 'unset';
    background-color: aliceblue;
    height: 22px;
    width: 36px;
    padding: 0;
    margin: 1px;
    box-shadow: 'inherit';
    border-radius: '50% 50% 50% 0';
    background-color: '#52af77';
}

.slider-top-row {
    display: inline-flex;
    align-items: center;
    width: auto;
    margin-left: auto;
    margin-right: 0;
}

.FormControlLabel {
    align-items: left;
}

.slider-name {
    display: inline-flex;
    font-size: 21px;
    font-weight: 300;
    float: left;
    margin-right: 5px;
    padding-bottom: 0;
}

.slider-values {
    margin-top: 4px;
    display: inline-flex;
    height: auto;
    position: absolute;
    right: 10px;
    margin-right: 10px;
}

.slider-units {
    left: 10;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 0;
    font-size: larger;
    font-weight: medium;
}

.slider-slider {
    padding-top: 8px;
    color: 'white';
    height: 8;
    border: 'none';
}

.filters-panel {
    padding-top: 108px;
    padding-right: 0;
    padding-left: 12px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 300px;
    max-width: 85%;
    background-color: #192044;
    z-index: 3;
    transition: width 0.5s ease-in-out;
    overflow-y: auto;
    opacity: 90%;
}